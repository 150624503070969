import axios from "axios";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { API_ENDPOINT, HEADER_TOKEN, MONTHS } from "../../constants/constants";
import { deleteToken, getHeader } from "../../utils/util";
import CardInfo from "../widgets/cardInfo";
import "./dashboard.css";
const Dashboard = () => {
  const [totalTest, setTotalTest] = useState("...");
  const [activeTrainers, setActiveTrainers] = useState("...");
  const [expiredTrainers, setExpiredTrainers] = useState("...");
  const [totalMockTest, setTotalMockTest] = useState("...");
  const currentYear = new Date().getFullYear();
  const [assessmentsOverview, setAssessmentsOverview] = useState([]);
  const [mocktestsOverview, setMockTestsOverview] = useState([]);
  const [aOcurrentYear, setAoCurrentYear] = useState(currentYear);
  const [mOcurrentYear, setMoCurrentYear] = useState(currentYear);
  let sDate = new Date();
  let sYear = sDate.getFullYear();
  let len = 5;
  let pYears = Array.from({ length: len }, (_, i) => currentYear - i);
  const [pastYears, setPastYears] = useState(pYears);

  function getTrainersOverview() {
    axios
      .post(
        API_ENDPOINT + "admin/trainers-overview",
        { year: aOcurrentYear },
        getHeader()
      )
      .then((res) => {
        let d = res.data;
        if (d.success) {
          let asA = [["Months", "New Trainers", "Trainers with Assessment"]];
          let resA = d.message;
          MONTHS.map((m, i) => {
            let mn = m.slice(0, 3);
            let foundO = resA.find((r) => r._id === i + 1);
            if (foundO !== undefined) {
              asA.push([
                mn,
                foundO.newTrainerCount,
                foundO.trainersWithAssessments || 0,
              ]); // Include mock test data
            } else {
              asA.push([mn, 0, 0]);
            }
          });
          setAssessmentsOverview(asA);
        }
      });
  }

  function getMocktestsOverview() {
    axios
      .post(
        API_ENDPOINT + "admin/mocktests-overview",
        { year: mOcurrentYear },
        getHeader()
      )
      .then((res) => {
        let d = res.data;
        if (d.success) {
          let asA = [["Months", "Total Users", "Total Mocktests"]];
          let resA = d.message;
          MONTHS.map((m, i) => {
            let mn = m.slice(0, 3);
            let foundO = resA.find((r) => r._id === i + 1);
            if (foundO !== undefined) {
              asA.push([
                mn,
                foundO.uniqueCandidatesCount,
                foundO.numMocktests || 0,
              ]);
            } else {
              asA.push([mn, 0, 0]);
            }
          });
          setMockTestsOverview(asA);
        } else {
          if (d.auth === false) {
            logout();
          }
        }
      });
  }
  const logout = () => {
    deleteToken();
    document.location.href = "/login";
  };
  const assessmentsOverviewOptions = {
    title: `Assessment Statistics ${aOcurrentYear}`,
    legend: { position: "top", alignment: "center" },
    hAxis: { title: "Months" },
    vAxes: {
      0: { title: "New Trainers" }, // Left Y-axis
      1: { title: "Trainers with Assessment" }, // Right Y-axis
    },
    series: {
      0: { targetAxisIndex: 0, type: "bars", color: "#76A7FA" }, // Bar for Candidates Count
      1: { targetAxisIndex: 1, type: "line", color: "#4CAF50" }, // Line for Assessment Count
    },
    height: 400,
    width: "100%",
    chartArea: { width: "80%", height: "70%" }, // Adjust for cleaner layout
  };

  const mocktestsOverviewOptions = {
    title: `Mocktest Statistics ${aOcurrentYear}`,
    legend: { position: "top", alignment: "center" },
    hAxis: { title: "Months" },
    vAxes: {
      0: { title: "Total Users" }, // Left Y-axis
      1: { title: "Total Mocktests" }, // Right Y-axis
    },
    series: {
      0: { targetAxisIndex: 0, type: "bars", color: "#76A7FA" }, // Bar for Candidates Count
      1: { targetAxisIndex: 1, type: "line", color: "#4CAF50" }, // Line for Assessment Count
    },
    height: 400,
    width: "100%",
    chartArea: { width: "80%", height: "70%" }, // Adjust for cleaner layout
  };

  function getAssessmentNum() {
    axios
      .get(API_ENDPOINT + "admin/get-total-test", getHeader())
      .then((res) => {
        let d = res.data;
        if (d.success) {
          setTotalTest(d.message);
        }
      });
  }
  function getTotalTrainers() {
    axios
      .get(API_ENDPOINT + "admin/get-total-trainers", getHeader())
      .then((res) => {
        let d = res.data;
        if (d.success) {
          setActiveTrainers(d.activeTrainers);
          setExpiredTrainers(d.expiredTrainers)
        }
      });
  }
  function getTotalMockTest() {
    axios
      .get(API_ENDPOINT + "admin/get-total-mock-test", getHeader())
      .then((res) => {
        let d = res.data;
        if (d.success) {
          setTotalMockTest(d.message);
        }
      });
  }
  useEffect(() => {
    getAssessmentNum();
    getTotalTrainers();
    getTotalMockTest();
  }, []);
  useEffect(() => {
    getTrainersOverview();
  }, [aOcurrentYear]);
  useEffect(() => {
    getMocktestsOverview();
  }, [mOcurrentYear]);
  return (
    <div className="sam-dashboard">
      <div className="card_info_c">
        <CardInfo
          title="Active Trainers"
          num={activeTrainers}
          pc="12"
          duration="30"
          bg="#7DA0FA"
        />
        <CardInfo
          title="Inactive Trainers"
          num={expiredTrainers}
          pc="5"
          duration="21"
          bg="#4747A1"
        />
        <CardInfo
          title="Total Test"
          num={totalTest}
          pc="50"
          duration="56"
          bg="#7978E9"
        />
        <CardInfo
          title="Total Mock Test"
          num={totalMockTest}
          pc="4"
          duration="3"
          bg="#F3797E"
        />
      </div>
      <div className="chart_c">
        <div className="chart_item first">
          <select
            value={aOcurrentYear}
            onChange={(e) => setAoCurrentYear(e.target.value)}
            style={{ position: "absolute", zIndex: 10, right: 30 }}
          >
            {pastYears &&
              pastYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
          {assessmentsOverview.length > 0 && (
            <Chart
              chartType="ComboChart" // Use ComboChart for mixed bar and line
              width="100%"
              height="400px"
              data={assessmentsOverview}
              options={assessmentsOverviewOptions}
            />
          )}
        </div>

        <div className="chart_item">
          <select
            value={mOcurrentYear}
            onChange={(e) => setMoCurrentYear(e.target.value)}
            style={{ position: "absolute", zIndex: 10, right: 30 }}
          >
            {pastYears &&
              pastYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
          {mocktestsOverview.length > 0 && (
            <Chart
              chartType="ComboChart"
              width="100%"
              height="400px"
              data={mocktestsOverview}
              options={mocktestsOverviewOptions}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
