import React, { useEffect, useState } from "react";
import { getDateFormat, getHeader } from "../../utils/util";
import { Button, TextField } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import {toast } from "react-toastify";
import { API_ENDPOINT, HEADER_TOKEN } from "../../constants/constants";
import axios from "axios"
const TopUp = ({ user, show }) => {
    const [ac, setAc] = useState("");
    const addTopUp = ()=>{
        if(ac ===""){
            toast("Please Enter Assessment count", {position: 'bottom-center', autoClose: 1000})
        }else{
            axios.post(API_ENDPOINT+'admin/trainer-topup', {phone: user.phone, assessmentCount: ac}, HEADER_TOKEN).then(res=>{
                let d = res.data
                if(d.success){
                    setAc("");
                    toast(d.message, {autoClose: 900, position: 'top-right'})
                }else{
                    toast(d.message, {autoClose: 900, position: 'top-right'})
                }
            })
        }
    }
  return (
    <div style={{ maxWidth: "600px", margin: "auto", position: "relative" }}>
      <div className="rd-table-container">
        <table>
          <colgroup>
            <col span="1" style={{ width: "1%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "19%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
          </colgroup>
          <tbody>
            <tr>
              <th>Name:</th>
              <th>{user.name}</th>
            </tr>
            <tr>
              <th>Plan:</th>
              <th>{user && user.plan && user.plan.name}</th>
            </tr>
            <tr>
              <th>Status:</th>
              <th>{user && user.plan && user.plan.status}</th>
            </tr>
          </tbody>
        </table>
        <div style={{ margin: "30px" }}>
          <TextField
            value={ac} onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) { // Allow only digits
                  setAc(value);
                }
              }}
              
            variant="outlined"
            label="Assessment Count"
            size="small"
          />
          <Button
            onClick={addTopUp}
            variant="outlined"
            startIcon={<AddCircle />}
            sx={{ marginLeft: "6px", alignSelf: "center" }}
          >
            Top Up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TopUp;
