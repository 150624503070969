import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_ENDPOINT, PAGE_SIZE } from "../../constants/constants";
import {
  getDateFormat,
  getHeader,
  getDifferenceInDays,
} from "../../utils/util";
import "./membership.css";
import SearchBar from "../widgets/searchBar";
import { Button } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import CustomCircularP from "../widgets/customCircularP";
import * as XLSX from "xlsx";
import MyModal from "../widgets/myModal/myModal";
import PlanHistory from "./PlanHistory";
import TopUp from "./TopUp";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
const Membership = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [planHistory, setPlanHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQ, setSearchQ] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const [showTopUp, setShowTopUp] = useState(false);
  const [topUpUser, setTopUpUser] = useState("");

  const viewPlanHistory = (phone) => {
    getPlanHistory(phone);
    setShowHistory(true);
  };

  function getPlanHistory(phone) {
    setLoading(true);
    axios
      .post(
        API_ENDPOINT + "admin/transaction-history",
        { phone: phone },
        getHeader()
      )
      .then((res) => {
        let d = res.data;
        if (d.success) {
          console.log(d.message);
          setPlanHistory(d.message);
          setLoading(false);
        }
      });
  }
  useEffect(() => {
    getMembers();
  }, [searchQ]);
  function getMembers() {
    setLoading(true);
    axios
      .post(
        API_ENDPOINT + "admin/get-trainers",
        { page: currentPage, searchQ: searchQ },
        getHeader()
      )
      .then((res) => {
        setLoading(false);
        let d = res.data;
        if (d.success) {
          setUsers(d.message);
        } else {
          toast(d.message);
        }
      });
  }
  const onSearch = (e) => {
    setSearchQ(e.target.value);
  };
  const onExport = () => {
    let newUsers = [];
    users.map((user, i) => {
      newUsers.push({
        name: user.name,
        email: user.email,
        phone: user.phone,
        plan: user.plan.name,
      });
    });

    const ws = XLSX.utils.json_to_sheet(newUsers);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "output.xlsx");
  };

  const onTopUp = (user) => {
    setTopUpUser(user);
    setShowTopUp(true);
  };

  return (
    <div className="membership-container">
      <CustomCircularP show={loading} />
      <div style={{ display: "flex", marginBottom: "12px" }}>
        <div style={{ flex: 1 }}>
          <SearchBar
            onSearch={onSearch}
            placeholder="Search by Name"
            size="8px"
            cstyle={{ maxWidth: "400px" }}
          />
        </div>
        <Button
          onClick={onExport}
          variant="outlined"
          size="small"
          startIcon={<ListAltIcon />}
        >
          Export
        </Button>
      </div>
      <div className="rd-table-container">
        <table>
          <colgroup>
            <col span="1" style={{ width: "2%" }} />
            <col span="1" style={{ width: "15%" }} />
            <col span="1" style={{ width: "15%" }} />
            <col span="1" style={{ width: "13%" }} />
            <col span="1" style={{ width: "15%" }} />
            <col span="1" style={{ width: "15%" }} />
            <col span="1" style={{ width: "12%" }} />
            <col span="1" style={{ width: "13%" }} />
          </colgroup>
          <tbody>
            <tr>
              <th>SI.no.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Plan</th>
              <th>Joined On</th>
              <th>Last Used On</th>
              <th>View</th>
            </tr>
            {users.map((od, i) => (
              <tr>
                <td>{(currentPage - 1) * PAGE_SIZE + i + 1}</td>
                <td>{od.name}</td>
                <td>{od.email}</td>
                <td>{od.phone}</td>
                <td>
                  {od.plan ? (
                    <div>
                      <div>
                        <div style={{ color: "#444", fontWeight: 600 }}>
                          {od.plan.name}
                        </div>
                        <div style={{ fontSize: "14px", marginTop: "8px" }}>
                          Subscribed on:{" "}
                          <span>
                            {getDateFormat(new Date(od.plan.createdOn))}
                          </span>
                        </div>
                        <div style={{ fontSize: "14px", marginTop: "8px" }}>
                          Remaining Days:{" "}
                          <span>
                            {od.plan.status === "Expired"
                              ? od.plan.endsOn
                                ? `Expired on ${getDateFormat(
                                    new Date(od.plan.endsOn)
                                  )}`
                                : "Expired"
                              : getDifferenceInDays(new Date(), od.plan.endsOn)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    "Not Subscribed"
                  )}
                </td>
                <td>{getDateFormat(new Date(od.createdOn))}</td>
                <td>
                  {od.plan &&
                    od.plan.usedDate &&
                    getDateFormat(new Date(od.plan.usedDate))}
                </td>
                <td>
                  {/* <PreviewIcon onClick={() => viewPlanHistory(od.phone)} /> */}
                  <Button
                    onClick={() => viewPlanHistory(od.phone)}
                    variant="outlined"
                    size="small"
                    startIcon={<PreviewIcon />}
                  >
                    Transactions
                  </Button>
                  <Button
                    style={{ marginTop: "10px" }}
                    onClick={() => onTopUp(od)}
                    variant="outlined"
                    size="small"
                    startIcon={<DashboardCustomizeOutlinedIcon />}
                  >
                    TopUp
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <MyModal
        showModal={showHistory}
        title="All Transactions"
        setShowModal={setShowHistory}
        modalC={
          <PlanHistory
            show={showHistory}
            onActionDone=""
            mode=""
            planHistory={planHistory}
          />
        }
      />
      <MyModal
        showModal={showTopUp}
        title="Top Up"
        setShowModal={setShowTopUp}
        modalC={<TopUp show={showTopUp} user={topUpUser} />}
      />
    </div>
  );
};
export default Membership;
