import React, { useEffect, useState } from "react";
import { getDateFormat, getHeader } from "../../utils/util";

const PlanHistory = ({ planHistory, mode, onActionDone, show }) => {
  return (
    <div style={{margin: "auto", position: "relative" }}>
      <div className="rd-table-container">
        <table>
          <colgroup>
            <col span="1" style={{ width: "01%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "14%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "15%" }} />
          </colgroup>
          <tbody>
            <tr>
              <th>Sl.No.</th>
              <th>Subscribed On</th>
              <th>Plan</th>
              <th>Assessment Count</th>
              <th>Cost</th>
              <th>Invoice Link</th>
              <th>Resend Invoice</th>
            </tr>
            {planHistory.map((od, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{getDateFormat(new Date(od.createdOn))}</td>
                <td>{od.plan_name}</td>
                <td>{od.amount}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanHistory;
